import { Injectable } from '@angular/core';
import { getPermissionState } from '@app/reducers/auth/permission.helper';
import { getAuthenticatedUser } from '@app/reducers/orm/employee/employee.service';
import { UserGuideModel, UserGuideStatus, UserGuideType } from '@app/reducers/orm/user-guide/user-guide.model';
import { getUserGuideEntities, isUserGuideFinished } from '@app/reducers/orm/user-guide/user-guide.selector';
import { UserGuideService } from '@app/reducers/orm/user-guide/user-guide.service';
import { getPageParamsState } from '@app/reducers/page-params-state/page-params.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SbDialogService } from '@sb/ui';
import { BehaviorSubject, filter, map, Observable, switchMap, withLatestFrom } from 'rxjs';

export enum ChecklistStep {
  COMPLETE_SIGNUP = 'COMPLETE_SIGNUP',
  SETUP_ORGANIZATION_SETUP = 'SETUP_ORGANIZATION_SETUP',
  ADD_EMPLOYEES = 'ADD_EMPLOYEES',
  SCHEDULE_EMPLOYEE = 'SCHEDULE_EMPLOYEE',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
}

export interface ChecklistModel {
  progress: number;
  tasks: ChecklistTaskModel[];
  remainingSteps: number;
}

interface ChecklistTaskModel {
  title: string;
  isCompleted: boolean;
  link: string[];
}

interface UserGuideModelEnchanced extends UserGuideModel {
  steps: ChecklistStep[];
}

@Injectable({
  providedIn: 'root',
})
export class OnboardingChecklistService {
  public totalSteps: number;

  public authUser$ = this.store.select(getAuthenticatedUser);
  public scheduleLink: string[] | any[];

  public showChecklist: boolean;

  private userGuide$: Observable<UserGuideModelEnchanced> = this.store.select(getUserGuideEntities).pipe(
    map((userGuideEntities) => userGuideEntities[UserGuideType.MANAGER_ONBOARDING_CHECKLIST]),
    map((guide) => {
      if (guide && guide.status === UserGuideStatus.IN_PROGRESS) {
        return {
          ...guide,
          steps: this.splitAndValidateChecklistStepEnum(guide.step).map((step) => step.trim()) as ChecklistStep[],
        };
      }
      return null;
    }),
  );

  public steps$ = new BehaviorSubject<ChecklistStep[]>(undefined);

  public isChecklistInProgress$: Observable<boolean> = this.userGuide$.pipe(
    withLatestFrom(this.store.select(getPermissionState)),
    map(([guide, permissionState]) => {
      if (!permissionState.isAccountManager) {
        return false;
      }

      if (!guide) {
        return false;
      }

      return guide.status === UserGuideStatus.IN_PROGRESS;
    }),
  );

  public get steps(): ChecklistStep[] | undefined {
    return this.steps$.value;
  }

  public items$: Observable<ChecklistModel> = this.userGuide$.pipe(
    withLatestFrom(
      this.store.select(getPermissionState),
      this.store.select(getPageParamsState),
      this.store.pipe(select(isUserGuideFinished(UserGuideType.SIGNUP_GREETING_MODAL))),
    ),
    map(([guide, permissionState, pageParams, signupGreetingFinished]) => {
      if (pageParams) {
        this.scheduleLink = [
          'schedule',
          pageParams.schedule.mode,
          pageParams.schedule.period,
          { date: pageParams.schedule.date },
        ];
      }

      this.steps$.next(guide?.steps);
      const tasks = this.tasks;
      this.totalSteps = tasks.length;

      if (!guide || permissionState.isAccountManager === false) {
        return null;
      }

      if (signupGreetingFinished) {
        this.showChecklist = true;
      }

      const progress = (this.steps.length / this.totalSteps) * 100;
      const remainingSteps = this.totalSteps - this.steps.length;
      return {
        progress,
        tasks,
        remainingSteps,
      };
    }),
  );

  public constructor(
    private readonly store: Store,
    private readonly userGuideService: UserGuideService,
    private readonly translate: TranslateService,
    private readonly dialog: SbDialogService,
  ) {}

  public initGuide() {
    void this.userGuideService
      .trackUserGuide({
        guide: UserGuideType.MANAGER_ONBOARDING_CHECKLIST,
        status: UserGuideStatus.IN_PROGRESS,
        step: ChecklistStep.COMPLETE_SIGNUP,
      })
      .subscribe();
  }

  public updateSteps(step: ChecklistStep): void {
    if (!this.steps || this.steps.includes(step)) {
      return;
    }

    const steps = [...this.steps, step];
    const guideStatus = steps.length === this.totalSteps ? UserGuideStatus.FINISHED : UserGuideStatus.IN_PROGRESS;

    void this.userGuideService
      .trackUserGuide({
        guide: UserGuideType.MANAGER_ONBOARDING_CHECKLIST,
        status: guideStatus,
        step: guideStatus !== UserGuideStatus.FINISHED ? steps.join(',') : null,
      })
      .subscribe();
  }

  public dismiss(): Observable<void> {
    return this.dialog
      .openConfirm({
        type: 'warning',
        title: this.translate.instant('Dismiss setup guide?'),
        description: this.translate.instant(
          'If you dismiss it now, you might miss out on valueable tips and insights that can streamline your journey.',
        ),
        primary: {
          text: this.translate.instant('Dismiss'),
        },
      })
      .closed.pipe(
        filter((result) => result?.confirmed),
        switchMap(() =>
          this.userGuideService.trackUserGuide({
            guide: UserGuideType.MANAGER_ONBOARDING_CHECKLIST,
            status: UserGuideStatus.DISMISSED,
            step: null,
          }),
        ),
      );
  }

  private isCompleted(step: ChecklistStep): boolean {
    return this.steps.includes(step);
  }

  private get tasks(): ChecklistTaskModel[] {
    return [
      {
        title: this.translate.instant('Complete your signup'),
        isCompleted: this.steps ? this.isCompleted(ChecklistStep.COMPLETE_SIGNUP) : false,
        link: [''],
      },
      {
        title: this.translate.instant('Setup your company’s organization'),
        isCompleted: this.steps ? this.isCompleted(ChecklistStep.SETUP_ORGANIZATION_SETUP) : false,
        link: ['account/settings/locations'],
      },
      {
        title: this.translate.instant('Add your employees'),
        isCompleted: this.steps ? this.isCompleted(ChecklistStep.ADD_EMPLOYEES) : false,
        link: ['employees'],
      },
      {
        title: this.translate.instant('Schedule an employee'),
        isCompleted: this.steps ? this.isCompleted(ChecklistStep.SCHEDULE_EMPLOYEE) : false,
        link: this.scheduleLink,
      },
      {
        title: this.translate.instant('Verify your email address'),
        isCompleted: this.steps ? this.isCompleted(ChecklistStep.VERIFY_EMAIL) : false,
        link: ['my-account/login'],
      },
    ];
  }

  public splitAndValidateChecklistStepEnum(input: string): ChecklistStep[] {
    const validChecklistStep = input
      .split(',')
      .filter((step) => Object.values(ChecklistStep).includes(step as ChecklistStep)) as ChecklistStep[];

    return validChecklistStep;
  }
}
